// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.text-side td {
    writing-mode: vertical-lr;
    -ms-writing-mode: tb-rl;
    transform: rotate(180deg);                        
}

.table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
}

#spinner {
    z-index: 1040;
    backdrop-filter: blur(2px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}