// Body
$body-bg: #feffff;

$myprimary: #f35517;
$mysecondary: #f1bf90;
$black: #0e0e0e;
$green: #35c72d;
$yellow: #f3af3b;
$blue: #4189f4;
$red: #f72435;


$primary: $myprimary;
$secondary: $mysecondary;
$success: $green;
$info: $blue;
$danger: $red;
$warning: $yellow;
$light: $body-bg;


// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// checkbox border
$form-check-input-border: 2px solid rgba($black, .35);
$form-check-input-width: 1.5em;